import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/.pnpm/jotai@2.12.2_@types+react@19.0.12_react@19.0.0/node_modules/jotai/esm/react.mjs");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/.pnpm/next@15.2.3_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0_sass@1.86.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"openSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/home/vsts/work/1/s/node_modules/.pnpm/nextjs-toploader@3.7.15_next@15.2.3_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19._acd8fd040629dacd4587c9c5f6668969/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["LocalHostRedirect"] */ "/home/vsts/work/1/s/packages/app/src/app/LocalHostRedirect.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppInsightsWrapper"] */ "/home/vsts/work/1/s/packages/app/src/components/AppInsightsWrapper/AppInsightsWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FullStoryWrapper"] */ "/home/vsts/work/1/s/packages/app/src/components/FullStory/FullStoryWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/packages/app/src/components/jotai/DevOnlyJotaiDebugging.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/packages/app/src/components/styles/GlobalStyles.tsx");
